import Vue from 'vue'
// 引入路由第三方包
import VueRouter from 'vue-router'
// 在vue项目中使用vue-router进行管理
Vue.use(VueRouter)
 
// 引入组件
import HomeView from '@/views/HomeView.vue'
import TermsOfUseView from '@/views/TermsOfUseView.vue'
import PrivacyView from '@/views/PrivacyView.vue'
import ShopView from '@/views/ShopView.vue'
 
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: '/',
      component: HomeView,
      title: "Game Wonder"
    },
    {
      path: '/TermsOfUse',
      component: TermsOfUseView,
      title: "TermsOfUseView"
    },
    {
      path: '/Privacy',
      component: PrivacyView,
      title: '/Privacy',
    },
    {
      path: '/Shop',
      component: ShopView,
      title: 'Shop',
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
    },
})

router.beforeEach(function(to, from, next){
  if (to.title){
    document.title = to.title;
  }else{
    document.title = "Game Wonder";
  }
  next();
})
 
export default router
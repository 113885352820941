<template>
  <div class="home">
    <div class="header">
      <div class="topBg"></div>
      <img class="logo" src="@/assets/logo.png"/>
      <div class="name">Hit Fortune - Casino Slots</div>
      <div class="head_right">
      <div class="btn_store" @click="onClickS">
        <div class="store"></div>
        <img class="icon_store" src="@/assets/icon_store.png"/>
        <div class="text_store">STORE</div>
      </div>
      <a class="btn_download" target="_blank" href="https://galaxystore.samsung.com/detail/com.hitright.ssmasters.game">
        <div class="download"></div>
        <img class="icon_download" src="@/assets/icon_download.png"/>
        <div class="text_download">DOWNLOAD</div>
      </a>
    </div>
    <div class="banner">      
      <img class="banner_bg" src="@/assets/banner_bg.png">
      <div class="banner_content">
      <img class="banner_girl" src="@/assets/banner_girl.png"/>
      <img class="banner_boy" src="@/assets/banner_boy.png"/>
      <div class="banner_center">
        <div class="most_popular">MOST POPULAR</div>
        <div class="free_bg"></div>
        <div class="free_text">FREE TO PLAY</div>
        <div class="slots">SLOTS</div>
        <a target="_blank" href="https://galaxystore.samsung.com/detail/com.hitright.ssmasters.game">
          <div class="download_bg"></div>
          <div class="download_text">DOWNLOAD</div>
        </a>
      </div>
    </div>

    </div>
    </div>
    <div class="main">
      <div class="main_bg"></div>
      <div class="main-content">
      <div class="slot_game_title">A Multitude Of Elegantly Designed Slot Games</div>
      <div class="slot_game_text">Experience a vast collection of free-to-play social casino-style games. Immerse yourself in a thrilling gaming atmosphere with diverse casino games. Spin the reels, make your bets, and try your luck with our exciting lineup of free titles.</div>
      <img class="g1" src="@/assets/g1.png"/>
      <img class="g2" src="@/assets/g2.png"/>
      <img class="g3" src="@/assets/g3.png"/>
      <img class="g4" src="@/assets/g4.png"/>
      <img class="g5" src="@/assets/g5.png"/>
      <img class="g6" src="@/assets/g6.png"/>
      <img class="g7" src="@/assets/g7.png"/>
      <img class="g8" src="@/assets/g8.png"/>
    </div>
    </div>
    <div class="feature_display_page">
      <div class="feature_bg"></div>
      <div class="feature_content">
      <img class="icon_blue1" src="@/assets/icon_blue1.png"/>
      <div class="title_blue1">A Myriad Of Slot Games Awaits Your Enjoyment</div>
      <div class="text_blue1">Hit Fortune features A myriad of slot games awaits your enjoyment, including popular titles like Mega greatest catch, Temple of thunder, and Gold of Sirens. With new games added weekly, you'll never run out of exciting options to play.</div>
      <img class="icon_blue2" src="@/assets/icon_blue2.png"/>
      <div class="title_blue2">Exceptional User Experience</div>
      <div class="text_blue2">If you're looking for a slots game with an exceptional gaming experience, Hit Fortune is the perfect choice. With high-quality visuals, outstanding music and sound effects, it offers an immersive journey that transports you into the world of gaming.</div>
      <img class="icon_blue3" src="@/assets/icon_blue3.png"/>
      <div class="title_blue3">No Purchase Necessary</div>
      <div class="text_blue3">Hit Fortune is always free to play, with no purchase or paayment required You can get more free coins by mailing entries, winning comppetitions on collecting your daily free coins.</div>
      <img class="icon_blue4" src="@/assets/icon_blue4.png"/>
      <div class="title_blue4">Enjoy VIP Customer Support</div>
      <div class="text_blue4">Looking for a reliable free-to-play slots with top-notch custome support? Hit Fortune offers VIP assistance for any questions you may have about our website.</div>
    </div>
    </div>
    <div class="comments_page">
      <div class="comments_page_bg"></div>
      <div class="comments_content">
      <div class="comments_page_title">What Our Players Have to Say</div>
      <div>
        <div class="comment1_bg"></div>
        <div class="comment1_title">,,</div>
        <div class="comment1_text">I really enjoy this slot platform more than the other 20 or so platforms I play on.</div>
        <img class="comment1_head" src="@/assets/touxiang1.png"/>
        <div class="comment1_name">linus631</div>
      </div>
      <div>
        <div class="comment2_bg"></div>
        <div class="comment2_title">,,</div>
        <div class="comment2_text">This is actually a good good app.  I enjoy the V.I.P Bonuses & REWARDS!!!!</div>
        <img class="comment2_head" src="@/assets/touxiang2.png"/>
        <div class="comment2_name">Lip-wg1xe</div>
      </div>
      <div>
        <div class="comment3_bg"></div>
        <div class="comment3_title">,,</div>
        <div class="comment3_text">Fun, new, exciting! I really really love this game.</div>
        <img class="comment3_head" src="@/assets/touxiang3.png"/>
        <div class="comment3_name">patrickrain1914</div>
      </div>
    </div>
    </div>
    <div class="footer">
      <div class="footer_bg"></div>
      <div class="footer_content">
      <img class="footer_logo" src="@/assets/logo.png">
      <div class="footer_name">Hit Fortune - Casino Slots</div>
      <div class="footer_reserved">Copyright @Game Wonder. All rights reserved.</div>
      <div class="footer_introduce">NO PURCHASE IS NECESSARY to play. PROMOTIONS ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see <span class="termsOfUse" @click="onClickTS">Terms of Use</span>. Hit Fortune is a play-for-fun website intended for amusement purposes only. Hit Fortune does not offer "real-money gambling." Hit Fortune Platform is only open to Eligible Participants, who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry.</div>
      <a class="TermsLink" @click="onClickTS">Terms of Use</a>
      <div class="PrivacyLink" @click="onClickP">Privacy Policy</div>
    </div>
    </div>


  </div>
</template>

<script>
// import "@/css/home.css"
export default {
  title: "Game Wonder",
  name: 'HomeView',
  data: function(){
    return {
    }
  },
  props: {
  },
  mounted: function(){
    document.getElementById("body").style.backgroundColor = "#2c1f4d";
    // 监听窗口大小变化事件
    window.addEventListener('resize', function() {
      var width = window.innerWidth || document.documentElement.clientWidth;
    
      // 判断屏幕宽度，进行相应的操作
      if (width <= 1024) {
        // 屏幕宽度小于或等于768px时的处理逻辑
        // 例如：隐藏侧边栏，调整内容区宽度等
        // alert("width<750px")
        // 获取initial-scale的meta标签
      } else {
        // 屏幕宽度大于768px时的处理逻辑
        // 例如：显示侧边栏，恢复内容区宽度等
      }
    });
    // 页面加载完成后立即执行一次检查
    window.onload = function() {
      // 触发resize事件，以便在页面加载时立即进行尺寸检查
      window.dispatchEvent(new Event('resize'));
    };
  },
  beforeDestroy: function(){
    document.getElementById("body").style.backgroundColor = "";
  },
  methods: {
    onClickTS: function(){
      this.$router.push("TermsOfUse")
    },
    onClickP: function(){
      this.$router.push("Privacy")
    },
    onClickS: function(){
      this.$router.push("Shop")
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="@/css/home.css" scoped>
</style>
